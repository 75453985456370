import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import styled from "@mui/system/styled";

import { count, isDefined, validPostcode } from "../../config/helpers";
import CacheFactory from "../../Factories/CacheFactory";
import globalActions from "../../Redux/Global/actions";

import { calculateShipping, checkCarriageId } from "../../config/requests";
import { useRouter } from "next/router";
import { useTheme } from '@mui/material/styles';
import useGlobalSettings from "@components/_hooks/useGlobalSettings";
// import VatToggle from "../Header/VatToggle";

const InputContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #efe4e4',
    // backgroundColor: alpha(theme.palette.common.white, 0.75),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.common.white,
    },
    '&:active': {
        backgroundColor: theme.palette.common.white,
    },
    // marginRight: theme.spacing(2),
    color: '#000',
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    // //   marginLeft: theme.spacing(3),
    //   width: type == 'mobile' ? '100%' : 'auto',
    // },
}));
  
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    // marginLeft: '0.5em',
    color: 'inherit',
    // float: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        textIndent: '10px',
    //   padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
    //   paddingLeft: `calc(0.5em + ${theme.spacing(0.25)})`,
    //   transition: theme.transitions.create('width'),
      width: '100%',
      height: '22px'
    //   [theme.breakpoints.up('md')]: {
    //     width: '20ch',
    //   },
    },
}));



const FooterContact = ({isMobile}) => {


    const {getSetting} = useGlobalSettings();

    if(getSetting('show_home_order_form', 'yes') == 'no') return null;
      
    const dispatch = useDispatch();
    const router = useRouter();
    const theme = useTheme();

    const { userJourney } = useSelector(
        (state) => state.userjourneyReducer
    );
    const { VATFlag } = useSelector(
        (state) => state.globalReducer
    );

    const [numbers, setNumbers] = useState({
        firstNumber: 3,
        secondNumber: 5
    })

    useEffect(() => {
        let randomNumbers = {};
        if(!isDefined(CacheFactory.get('firstNumber'))) {
            randomNumbers.firstNumber = Math.floor((Math.random() * 10) + 1)
            CacheFactory.put('firstNumber', randomNumbers.firstNumber);
        }

        if(!isDefined(CacheFactory.get('secondNumber'))) {
            randomNumbers.secondNumber = Math.floor((Math.random() * 10) + 1)
            CacheFactory.put('secondNumber', randomNumbers.secondNumber);
        }


        randomNumbers = {
            firstNumber: CacheFactory.get('firstNumber'),
            secondNumber: CacheFactory.get('secondNumber'),
        }
        
        setNumbers({
            ...randomNumbers
        })
    }, [])
    
    const switchVat = () => {
        dispatch({ type: globalActions.GLOBAL_SWITCH_VAT_TOGGLE }); //function to toggle the vat switch once clicked (true/false)
    };

    
    const [submitForm, setSubmitForm] = useState({
        delivery_cost: {
            value: '',
            response: false,
            error: false
        },
        track_order: {
            value: '',
            response: false,
            error: false,
        }

    });
    const handleFormChange = (e) => {
        setSubmitForm({
            ...submitForm,
            [e.target.name]: {
                ...submitForm[e.target.name],
                value: e.target.value
            },
        })
    }

    const handleSubmit = (type) => {
        if(!type) return;

        const value = submitForm[type].value;
        let response = '';
        let error = false;
        if(type == 'delivery_cost') {
            if(validPostcode(value)){
                calculateShipping(value).then((res) => {
                    if(res.data.delivery_zone_status == 'enabled'){
                        if(res.data.delivery_charge == 0){
                            response = 'We are pleased to confirm that we deliver to your area free of charge.';
                        }
                        else {
                            response = 'We are pleased to confirm that we deliver to your area from as little as £'+res.data.delivery_charge+'.';
                        }
                    }
                    else {
                        response = 'We\'d like to provide an exact price for delivery to this postcode, please contact us.';
                    }
                    setSubmitForm({
                        ...submitForm,
                        [type]: {
                            ...submitForm[type],
                            response: response
                        }
                    })
                })

            } else {
                response = 'Postcode is invalid.'
                error = true;
                setSubmitForm({
                    ...submitForm,
                    [type]: {
                        ...submitForm[type],
                        response: response,
                        error: error
                    }
                })
            }

        }
        
        else if(type == 'track_order') {
            const url = '/tracking/' + submitForm[type].value
            if(count(value) < 6) {
                error = true;
                setSubmitForm({
                    ...submitForm,
                    [type]: {
                        ...submitForm[type],
                        response: 'Please enter at least 6 characters.',
                        error: error
                    }
                })
            }
            else {
                checkCarriageId(value).then((res) => {
                    if(res.data == false) {
                        response = 'Invalid tracking ref.',
                        error = error
                    }
                    else if(res.data){
                        router.push(url)
                    }
                    setSubmitForm({
                        ...submitForm,
                        [type]: {
                            ...submitForm[type],
                            response: response
                        }
                    })
                })
            }
        }
    }

    return (

        <Box sx={{backgroundColor: theme.palette.primary.main}} className={"text-center " + (isMobile == false ? 'mb-4' : '') }>
                <form className={!isMobile ? "boxed-padding " : "" + ""} method="post" onSubmit={handleSubmit}>
                        <Grid className="p-3" container>
                            <Grid xs={12} lg={6} item>
                                <label className="text-center font-light uppercase" style={{color: '#fff'}}>Calculate Delivery Cost</label>
                                <Box sx={{
                                    width: {
                                        lg: '375px',
                                        xs: 'fit-content' 
                                    }
                                }}  className="mt-2 d-flex margin-auto">
                                            <InputContainer className="">
                                                <StyledInputBase
                                                    inputProps={{ 'aria-label': 'delivery_cost', name: 'delivery_cost', placeholder: 'Postcode' }}
                                                    type="text"
                                                    value={submitForm.delivery_cost.value}
                                                    onChange={handleFormChange}
                                                />
                                            </InputContainer>
                                            <Button color="secondary" sx={{height: 'fit-content', width: '7em'}} className="ml-2" onClick={() => handleSubmit('delivery_cost')} variant="contained">Calculate</Button>
                                </Box>
                                    {submitForm.delivery_cost.response != false && (<Typography className="w-100" sx={{
                                        float: 'left', marginTop: '0.5em',
                                        color: (theme) => theme.palette.secondary.contrastText
                                    }} component="span">{submitForm.delivery_cost.response}</Typography>)}
                            </Grid>

                            <Grid sx={{
                                marginTop: {xs: '0.75rem', lg: 0}
                            }} xs={12} lg={6} item>
                            <label className="text-center font-light uppercase" style={{color: '#fff'}}>Track Your Order</label>
                            <Box sx={{
                                width: {
                                    md: '375px',
                                    xs: 'fit-content' 
                                }
                            }}  className="mt-2 d-flex margin-auto">
                                <InputContainer className="">
                                    <StyledInputBase
                                        inputProps={{ 'aria-label': 'track', name: 'track_order', placeholder: 'Tracking Ref.' }}
                                        type="text"
                                        value={submitForm.track_order.value}
                                        onChange={handleFormChange}
                                    />
                                </InputContainer>
                                <Button color="secondary" sx={{height: 'fit-content', width: '7em'}} className="ml-2" onClick={() => handleSubmit('track_order')} variant="contained">Track</Button>

                            </Box>
                                {submitForm.track_order.response != false && (<Typography className="w-100" sx={{
                                    float: 'left', marginTop: '0.5em',
                                    color: (theme) => theme.palette.white.main,
                                }}  component="span">{submitForm.track_order.response}</Typography>)}
                            </Grid>
                            {/* <Grid item xs={12} lg={4}>
                                <VatToggle
                                    switchVat={switchVat}
                                    VATFlag={VATFlag}
                                />
                            </Grid> */}
                        </Grid>
                </form>
        </Box>

    );
};

export default FooterContact;
